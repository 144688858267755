import React, {useEffect} from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { Helmet } from "react-helmet";

import Layout from "../components/layout";
import { VideoCover, Video } from "../components/Video";
import { BenefitsTwoCol, BenefitsGrid } from "../components/Content/Benefits";
import { ImageGrid, ImageCarousel } from "../components/Content/ImageCollection";
import TwoColumnContent from "../components/Content/TwoColumnContent";
import Testimonials from "../components/Content/Testimonials";
import {loadHero, textAlign, customColor} from "../lib/helpers";
import SEO from "../components/seo";

import style from "../styles/Landing.module.css";

const LandingPage = ({data}) => {

    useEffect(
        () => {
            window.location.replace("/")
        }, []
    )

    const content = data.contentfulGoBigTemplate;
    const heroCTA = content.heroCtaComponent;
    const leader = heroCTA.subHeadline;
    const pageName = content.pageName;
    const stackedSections = content.stackedSections;
    const customSections = content.customSection;
    const benefits = content.benefitsSection ? content.benefitsSection : false;
    const testimonials = content.testimonials;

    const headerData = {
        template: content.heroCtaComponent.templateLayout || "",
        heroCTA: content.heroCtaComponent || "",
        ctaUrl: content.conversionUrl ? content.conversionUrl : "https://account.upplan.sg/register",
        leader: leader,
        offer: content.desktopOffer || "",
        mediaLink: content.videoPath || "",
    }

    return (
        <Layout nav={true} props={content}>
            <SEO title={pageName} />
            <Helmet>
                {content.metaDescription && <meta name="description" content={content.metaDescription} />}
                {content.metaDescription && <meta property="og:description" content={content.metaDescription} />}
                {content.metaDescription && <meta name="twitter:description" content={content.metaDescription} />}
                {content.metaKeywords && <meta name="keywords" content={content.metaKeywords} />}
            </Helmet>

            {loadHero(heroCTA)}

            {stackedSections &&
            <section className="uk-section uk-section-large">
                <div className="uk-container uk-container-small uk-margin-large">
                    {content.benefitsHeadline.length && <h2 className={`${style.titleCenter} uk-margin-large-left uk-margin-large-right uk-text-center`}>{content.benefitsHeadline}</h2>}
                </div>
                {stackedSections.map((section, i) =>
                    <TwoColumnContent
                        key={section.slug}
                        title={section.title}
                        copy={section.copy.childMarkdownRemark.html}
                        image={section.image}
                        textAlign={section.textAlign ? section.textAlign : null}
                        slug={section.slug}
                        />
                )}
            </section>
            }

            {benefits &&
            <section className="uk-section uk-section-large uk-padding-remove-top">
                <BenefitsGrid benefits={benefits} />
            </section>
            }

            {customSections && customSections.length &&
                customSections.map((section, i) =>
                <section key={section.slug} className={`${style.customSection} uk-section uk-section-small ${customColor(section.sectionColor)}`}>
                    <div className="uk-container">
                        <div className="uk-margin-large-top uk-margin-large-bottom">
                            <div className="uk-grid uk-grid-large uk-flex-middle uk-child-width-1-2@s" data-uk-grid>
                                <div className={`${textAlign(section.textAlign)}`} data-uk-margin>
                                    <h2>{section.title}</h2>
                                    <div className="custom-section-copy" dangerouslySetInnerHTML={{ __html: section.copy.childMarkdownRemark.html }} />
                                </div>
                                <div className="uk-flex-first" data-uk-margin>
                                    <Img
                                        fluid={section.image.fluid}
                                        alt={section.title}
                                        data-uk-img
                                         />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                )
            }

            {content.logoCollection && content.logoCollection.length &&
                <section className="logo-grid uk-section uk-section-large">
                    {content.logoCollection.map((fields, i) => {
                            console.log("IMAGE FIELDS", fields)
                            return (
                                fields.displayFormat === 'carousel' ?
                                    <ImageCarousel
                                        key={fields.collectionIdentity}
                                        slug={fields.collectionIdentity}
                                        headline={fields.headline ? fields.headline : null}
                                        subHeadline={fields.subHeadline ? fields.subHeadline : null}
                                        displayWidth={fields.displayWidth}
                                        sectionColor={fields.sectionColor ? fields.sectionColor : null}
                                        images={fields.images}
                                        />
                                    :
                                    <ImageGrid
                                        key={fields.collectionIdentity}
                                        slug={fields.collectionIdentity}
                                        headline={fields.headline ? fields.headline : null}
                                        subHeadline={fields.subHeadline ? fields.subHeadline : null}
                                        displayWidth={fields.displayWidth}
                                        sectionColor={fields.sectionColor ? fields.sectionColor : null}
                                        images={fields.images}
                                        />
                            )
                        }
                    )}
                </section>
            }

            {/* testimonials && testimonials.length && <Testimonials data={testimonials} /> */}

        </Layout>
    )
}

export default LandingPage;

export const goBigPageQuery = graphql`
    query goBigPageQuery($pageIdentifier: String) {
        contentfulGoBigTemplate(pageIdentifier: {
            eq: $pageIdentifier
        }) {
            pageIdentifier
            pageName
            conversionUrl
            metaDescription
            metaKeywords
            logo {
                code {
                    content {
                        content {
                            value
                        }
                    }
                }
            }

            heroCtaComponent {
                ...on ContentfulHeroCta {
                    name
                    headline
                    templateLayout
                    subHeadline {
                        childMarkdownRemark {
                            html
                        }
                        subHeadline
                    }
                    videoPath
                    sectionId
                    imageFile {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 1600) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }

            stackedSections {
                ...on ContentfulSectionItem {
                    slug
                    title
                    textAlign
                    image {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 800) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    copy {
                        childMarkdownRemark {
                            html
                        }
                    }
                }
            }

            customSection {
                slug
                sectionColor
                textAlign
                title
                copy {
                    childMarkdownRemark {
                        html
                    }
                }
                image {
                    fluid(maxWidth: 800) {
                        ...GatsbyContentfulFluid_withWebp
                    }
                }
            }

            benefitsHeadline
            benefitsSection {
                slug
                title
                copy {
                    copy
                    childMarkdownRemark {
                        html
                    }
                }
                image {
                    title
                    file {
                        contentType
                        url
                    }
                    fluid(maxWidth: 150) {
                        ...GatsbyContentfulFluid_withWebp
                    }
                }
            }

            logoCollection {
                collectionName
                collectionIdentity
                displayFormat
                displayWidth
                sectionColor
                headline
                images {
                    fluid(maxWidth: 150) {
                        ...GatsbyContentfulFluid_withWebp
                    }
                }
            }

            testimonials {
                slug
                source
                role
                copy {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }`
